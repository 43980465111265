/////////////////// Museo Sans
@import url("https://use.typekit.net/kqz3yzo.css");

///// Font mixin

@mixin fontSize($min-size, $size, $max-size, $line-height: 1.5, $total: 1440) {
  font-size: clamp(#{$min-size}, #{$size}vw, #{$max-size}) !important;
  line-height: #{$line-height};
}

///// Font

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  @include fontSize(1.4rem, 1.6, 1.8rem);
  font-family: $font-family-text;
  color: $color-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    font-family: $font-family-text;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-title;
  font-weight: 900;
}

p,
blockquote,
a,
span {
  font-family: $font-family-text;
  font-weight: 300;
}

h1,
h1 span {
  @include fontSize(
    #{$title-size-mobile}rem,
    $title-size-desktop,
    #{$title-size-desktop}rem
  );
}

h2,
h2 span {
  @include fontSize(
    #{$subtitle-size-mobile}rem,
    $subtitle-size-desktop,
    #{$subtitle-size-desktop}rem
  );
}

p,
p span,
a,
a span {
  @include fontSize(
    #{$text-size-mobile}rem,
    $text-size-desktop,
    #{$text-size-desktop}rem
  );
}

//   //   --fluid-type-min: 1.6rem;
//   //   --fluid-type-max: 1.8rem;
//   //   --fluid-type-target: 5vw;
//   //   max-width: 60ch;
//   //   font-size: clamp(
//   //     var(--fluid-type-min, 1rem),
//   //     calc(1rem + var(--fluid-type-target, 3vw)),
//   //     var(--fluid-type-max, 1.3rem)
//   //   );
//   // https://piccalil.li/tutorial/fluid-typography-with-css-clamp/
// }

// p,
// blockquote,
// a,
// span {
//   font-family: $font-family-text;
//   font-weight: normal;
// }

// h1,
// h1 span {
//   font-weight: bold;
//   font-size: $title-size-mobile;
//   line-height: calc(#{$title-size-mobile});

//   @include for-tablet-portrait-up {
//     font-size: $title-size-tablet-portrait;
//     line-height: calc(#{$title-size-tablet-portrait});
//   }

//   @include for-tablet-landscape-up {
//     font-size: $title-size-tablet-landscape;
//     line-height: calc(#{$title-size-tablet-landscape});
//   }

//   @include for-desktop-up {
//     font-size: $title-size-desktop;
//     line-height: calc(#{$title-size-desktop});
//   }
// }

// h2,
// h2 span {
//   font-weight: bold;
//   font-size: $subtitle-size-mobile;
//   line-height: calc(#{$subtitle-size-mobile});

//   @include for-tablet-portrait-up {
//     font-size: $subtitle-size-tablet-portrait;
//     line-height: calc(#{$subtitle-size-tablet-portrait});
//   }

//   @include for-tablet-landscape-up {
//     font-size: $subtitle-size-tablet-landscape;
//     line-height: calc(#{$subtitle-size-tablet-landscape});
//   }

//   @include for-desktop-up {
//     font-size: $subtitle-size-desktop;
//     line-height: calc(#{$subtitle-size-desktop});
//   }
// }

// p,
// p span,
// a,
// a span {
//   font-weight: normal;
//   font-size: $text-size-mobile;
//   line-height: calc(#{$text-size-mobile}+ 0.5rem);

//   @include for-tablet-portrait-up {
//     font-size: $text-size-tablet-portrait;
//     line-height: calc(#{$text-size-tablet-portrait} + 0.5rem);
//   }

//   @include for-tablet-landscape-up {
//     font-size: $text-size-tablet-landscape;
//     line-height: calc(#{$text-size-tablet-landscape} + 0.5rem);
//   }

//   @include for-desktop-up {
//     font-size: $text-size-desktop;
//     line-height: calc(#{$text-size-desktop} + 0.5rem);
//   }
// }
