$color-text: #000000;
$color-text--inverse: #ffffff;

$color-black: #000000;
$color-white: #ffffff;
$color-grey: #e8e8e8;

$color-blue--primary: #00a9ac;
$color-blue--secondary: #006a92;
$color-red--primary: #de4a2a;
$color-green--primary: #008a7b;
$color-green--secondary: #4eaf46;


$color-blue--dark: #002A57;
$color-blue--light: #0069B4;
$color-green: #AECD60;

$font-family-title: "Museo Sans", sans-serif;
$font-family-text: "Museo Sans", sans-serif;

$title-size-mobile: 3.5;
$title-size-desktop: 4.5;

$subtitle-size-mobile: 2.3;
$subtitle-size-desktop: 2.5;

$text-size-mobile: 1.5;
$text-size-desktop: 1.6;

$basic-transition-100: 100ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-transition-200: 200ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-transition-300: 300ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-transition-500: 500ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-transition-1000: 1s cubic-bezier(0.86, 0, 0.07, 1);

////// Swiper config
.swiper {
  --swiper-pagination-color: #e7792b;
  --swiper-pagination-bullet-height: 4px;
  --swiper-pagination-bullet-width: 15px;
  --swiper-pagination-bullet-inactive-color: white;
  --swiper-pagination-bullet-inactive-opacity: 1;

  --swiper-navigation-color: white;
  --swiper-navigation-size: 3rem;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 1.4rem !important;
}
