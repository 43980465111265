
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_fonts.scss";
          

































































































































































































@import "../scss/lib/swiper-bundle.min.css";

.modal {
  &__container {
    display: grid;
    position: absolute;
    background: rgba($color-black, 0.7);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1010;
    opacity: 0;
    visibility: hidden;
    transition: opacity $basic-transition-500 0.1s;

    &_open {
      pointer-events: all;
      opacity: 1;
      visibility: visible;

      .modal__inner {
        opacity: 1;
        top: 0;
        bottom: 0;
        visibility: visible;
        min-height: 100%;
        right: 0;

        width: 100%;

        .modal__content {
          opacity: 1;

          &__gallery {
            opacity: 1;
          }
        }

        @include for-desktop-up {
          width: 50%;
          height: 100%;
          max-height: 100%;
          max-width: 800px;
          top: 0;
          bottom: 0;
          right: 0;
          transform: unset;
        }
      }

      .modal__outer {
        pointer-events: all;
        opacity: 0.3;
      }
    }
  }

  &__inner {
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 1001;
    position: absolute;
    opacity: 0;
    right: -100%;

    transition: right $basic-transition-500 0.1s,
      opacity $basic-transition-500 0.1s;

    .modal__content {
      width: 100%;
      opacity: 0;
      transition: opacity $basic-transition-300 0.5s;
    }
  }

  &__outer {
    pointer-events: all;
    cursor: pointer;
    opacity: 0;
  }

  &--close {
    position: absolute;

    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    background: $color-black;
    padding: 1rem 1.6rem 1rem 2rem;
    border-radius: 50px;

    min-width: 15rem;

    -webkit-box-shadow: 0px 0px 30px 10px rgba(2, 2, 2, 0.15);
    box-shadow: 0px 0px 30px 10px rgba(2, 2, 2, 0.15);

    text-decoration: none;

    * {
      color: $color-text--inverse;
      font-weight: 900;
    }

    img {
      width: 28px;
      height: 28px;
      transition: transform $basic-transition-300;
      max-width: 18px;
      margin-left: 1rem;

      filter: invert(100%) sepia(84%) saturate(0%) hue-rotate(348deg)
        brightness(103%) contrast(101%);
    }

    &:hover img {
      transform: rotate(90deg);
    }
  }

  &__content {
    overflow: hidden;
    height: 100%;

    text-align: left;

    &__top {
      overflow: hidden;
      position: relative;
      height: 30%;
      min-height: 250px;

      iframe,
      object,
      embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      @include for-tablet-landscape-up {
        height: 40%;
      }
    }

    &__text {
      padding: 5.6rem 0.8rem 4.2rem 2.4rem;

      height: 100%;
      display: grid;
      grid-template: auto 1fr / 1fr;

      &.has-top-content {
        height: 70%;
        padding: 2.4rem 0.8rem 4.2rem 2.4rem;
      }

      h2 {
        color: $color-blue--light;
        margin-bottom: 2rem;
        line-height: 3rem;
        font-weight: 700;
      }

      p {
        line-height: 2.6rem;
        margin-bottom: 10rem;
      }

      &--inner {
        padding-right: 3.6rem;
        overflow: auto;
      }

      @include for-tablet-landscape-up {
        padding: 4.2rem 2.4rem 4.2rem 6.4rem;

        &.has-top-content {
          height: 60%;
          padding: 4.2rem 2.4rem 4.2rem 6.4rem;
        }
      }
    }

    &__gallery {
      opacity: 0;
      transition: transform $basic-transition-300;
    }

    &__actions {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      @include for-tablet-portrait-up {
        flex-direction: row;
        justify-content: space-between;
      }

      a {
        margin-top: 24px;
        color: darken($color-blue--dark, 10%);
        transition: color $basic-transition-200;
        display: block;

        img {
          height: 24px;
          vertical-align: middle;
          margin-left: 0;
          transition: margin $basic-transition-200;
        }

        &:hover {
          color: $color-text;

          img {
            margin-left: 4px;
          }
        }
      }
    }
  }
}

.swiper {
  .img__wrapper {
    width: 100%;
    height: 100%;
    cursor: pointer;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      transition: all $basic-transition-200;
    }
  }

  &-wrapper {
    // min-height: 200px;
  }

  &-pagination {
    position: absolute;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
    display: grid;
    grid-auto-flow: column;
    grid-gap: 12px;

    border-radius: 5px 5px 0 0;
    z-index: 10;
    padding: 1.2rem 1.6rem;
    width: unset;

    cursor: pointer;
  }

  &-navigation {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba($color-black, 0.6);
    width: 6rem;
    height: 6rem;
    background-image: url("/assets/img/icons/icon-arrow.svg") !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 35%;

    &.swiper-button-next {
      right: 0;
    }

    &.swiper-button-prev {
      left: 0;
      transform: translateY(-50%) rotate(180deg);
    }

    &::after {
      content: "" !important;
    }
  }
}
