
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_fonts.scss";
          





































































































































































































$hotspot-dimension: 44px;

.hotspot {
  /*
    Position on ath/atv : krpano hack
  */
  position: absolute;
  cursor: pointer !important;
  pointer-events: all;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: $hotspot-dimension;
  height: $hotspot-dimension;
  border-radius: 50px;

  display: grid;
  place-content: center;

  transition: all $basic-transition-200;

  &:not(.hotspot__navigation) {
    background: $color-blue--light;
    box-shadow: 0 10px 20px 8px rgba(0, 0, 0, 0.15);
  }

  &__info:hover,
  &__image:hover {
    background: darken($color-blue--light, 5%);
    box-shadow: 0 10px 32px 10px rgba(0, 0, 0, 0.15);
  }

  &__inner {
    &__info,
    &__small-info,
    &__image {
      width: $hotspot-dimension;
      height: $hotspot-dimension;
      border-radius: 50px;

      img {
        // opacity: 0;
        height: 100%;
        width: 100%;
        transition: all $basic-transition-200;

        filter: invert(99%) sepia(16%) saturate(2%) hue-rotate(44deg)
          brightness(107%) contrast(101%) !important;

        transform: scale(0.8);
      }
    }
  }

  &__content {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;

    height: $hotspot-dimension;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 20px 0 50px;
    border-radius: 50px;
    opacity: 0;
    overflow: hidden;
    transition: all $basic-transition-200;

    span {
      opacity: 0;
      transition: all $basic-transition-1000;
    }

    &__bg {
      background: $color-blue--dark;
      width: 0%;
      height: 100%;
      left: 0;
      transition: all $basic-transition-500;
      position: absolute;
      z-index: -1;
    }

    &--small-info {
      padding: 2.4rem;
      flex-direction: column;
      // min-height: 200px;
      height: auto;
      border-radius: 8px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: -1;
      background: $color-white;
      opacity: 0;
      transition: all $basic-transition-200;

      display: flex;
      align-items: flex-start !important;
      flex-direction: column;
      justify-content: flex-start;
      min-width: 30rem;
      text-align: left;

      span {
        font-weight: bold;
        margin-bottom: 12px;
        font-size: 1.6rem;
        white-space: break-spaces;
        transition: all $basic-transition-500;
      }

      p {
        font-weight: normal;
        opacity: 0;
        font-size: 1.4rem;
        transition: all $basic-transition-500;
        white-space: break-spaces;
      }
    }

    * {
      color: $color-text;
      font-weight: bold;
      font-size: 14px;
    }
  }

  &__navigation:hover {
    .hotspot__top {
      top: 0px;
    }

    .hotspot__title {
      top: -25px;
      opacity: 1;
    }
  }

  &__title {
    color: $color-black;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -18px;
    opacity: 0;

    background: $color-white;
    border-radius: 3px;
    padding: 0.2rem 1.6rem;

    @include fontSize(1.5rem, 1.5, 1.5rem);
    font-weight: 700;

    transition: all $basic-transition-300;
  }

  &__top {
    background: $color-white;
    border: 9px solid $color-blue--light;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 5px;
    z-index: 1;

    transition: all $basic-transition-200;
  }

  &__line {
    background: $color-blue--light;
    width: 3px;
    height: 40px;
    position: absolute;
    top: unset;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__outer {
    background: $color-white;
    border-radius: 50%;
    width: 30px;
    height: 15px;
    position: absolute;
    top: unset;
    bottom: -10px;
    z-index: -3;
    left: 50%;
    transform: translateX(-50%);

    & .inner {
      background: $color-blue--light;
      position: absolute;
      top: unset;
      bottom: 0px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -65%);
      border-radius: 50%;
      width: 12px;
      height: 6px;
      z-index: -2;
    }
  }

  &:hover,
  &.touched {
    .hotspot__top {
      top: 0px;
    }

    .hotspot__title {
      top: -23px;
      opacity: 1;
    }

    &.hotspot__small-info {
      width: calc(#{$hotspot-dimension} + 6px);
      height: calc(#{$hotspot-dimension} + 6px);
      background: $color-white;

      img {
        opacity: 1;
        filter: none !important;
      }

      .hotspot__content {
        opacity: 1;
        pointer-events: all;

        span,
        p {
          opacity: 1;
        }

        &__bg {
          width: 100%;
        }
      }
    }
  }
}
