
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_fonts.scss";
          































































.socials {
  display: grid;
  flex-direction: column;
  grid-gap: 12px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    position: relative;

    img {
      position: relative;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      transition: opacity $basic-transition-200;
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(50deg)
        brightness(110%) contrast(100%);
    }

    &:hover {
      img {
        opacity: 1;
      }
    }
  }
}
