
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_fonts.scss";
          
html {
  
}

body,
#app {
  height: 100%;
  min-height: 100%;
}

body {
  font-size: 1.6rem;
  font-family: $font-family-text;
  color: $color-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0;
  padding: 0;

  * {
    font-family: $font-family-text;
  }
}

#krpanoObject {
  z-index: 1000;
}

body > #krpanoObject {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.btn {
  text-transform: uppercase;
  position: relative;
  border-radius: 6px;
  color: $color-black;
  padding: 12px;
  // letter-spacing: 2px;
  text-decoration: none;
  line-height: 18px;
  top: 24px;
  transition: $basic-transition-200;

  display: flex;
  align-items: center;

  &_shadow {
    box-shadow: 0px 0px 120px 10px #00000047;
  }

  &:active {
    transform: scale(1.05);
  }
}

.hidden {
  display: none !important;
}

.btn {
  text-transform: uppercase;
  position: relative;
  border-radius: 6px;
  color: $color-black;
  padding: 12px;
  // letter-spacing: 2px;
  text-decoration: none;
  line-height: 18px;
  top: 24px;
  transition: $basic-transition-200;

  display: flex;
  align-items: center;

  &_shadow {
    box-shadow: 0px 0px 120px 10px #00000047;
  }

  &:active {
    transform: scale(1.05);
  }
}

::-webkit {
  &-scrollbar {
    width: 6px;

    &-track {
      border-radius: 50px;
      background: transparent;
      z-index: 2;
      outline: 2px solid $color-grey;
    }

    &-thumb {
      border: 1px solid $color-white;
      border-radius: 50px;
      background: $color-grey;
      z-index: 1;

      &:hover {
        background: darken($color-grey, 10%);
      }
    }
  }
}

/* Tinybox styling */
.tinybox {
  z-index: 8000 !important;
  background: rgba(0, 0, 0, 1) !important;

  &__content {
    &__image {
      // border-radius: 4px !important;
    }

    &__control--close {
      top: 16px !important;
    }
  }
}

.modal {
  .swiper-pagination-bullet {
    border-radius: 50px !important;
  }
}

.invisible {
  opacity: 0 !important;
  pointer-events: none !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  z-index: -1 !important;
}
