.gui {
  z-index: 1001;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;

  * {
    pointer-events: all;
  }

  &__element {
    position: absolute;

    &--top-right {
      top: 24px;
      right: 24px;
    }

    &--top-left {
      top: 24px;
      left: 24px;
    }

    &--bottom-right {
      bottom: 24px;
      right: 24px;
    }

    &--bottom-left {
      bottom: 24px;
      left: 24px;
    }

    &--bottom-center {
      bottom: 24px;
      left: 50%;
      transform: translateX(-50%);
    }

    &--top-center {
      top: 24px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    transition: all $basic-transition-200;
    width: 30%;
    max-width: 150px;
    min-width: 100px;
    opacity: 1;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: 0 0;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__title {
    left: unset;
    bottom: 0;
    font-weight: bold;
    word-break: break-all;
    transition: letter-spacing $basic-transition-300;
    max-width: unset;
    z-index: 1;
    width: 100%;
    padding: 10rem 2.4rem 2.4rem 2.4rem !important;
    pointer-events: none;

    &--blue-dark {
      background: linear-gradient(
        0deg,
        rgba($color-blue--dark, 0.9) 40%,
        transparent 95%
      );
    }

    &--blue-light {
      background: linear-gradient(
        0deg,
        rgba($color-blue--light, 0.9) 40%,
        transparent 95%
      );
    }

    &--green {
      background: linear-gradient(
        0deg,
        rgba($color-green, 0.9) 40%,
        transparent 95%
      );
    }

    @include for-tablet-portrait-up {
      padding: 0 !important;
      min-width: 400px;
      max-width: 400px;
      left: 24px;
      bottom: 42px;
      background: transparent;
    }

    @include for-tablet-landscape-up {
      left: 56px;
      bottom: 72px;
    }

    * {
      pointer-events: all;
      display: flex;
      width: auto;
      word-break: normal;
      color: $color-text--inverse;
    }

    h2 {
      @include fontSize(1.8rem, 2, 2rem, 1.25);
      margin-bottom: 12px;
      font-weight: 500;
      max-width: 80%;
    }

    h1 {
      @include fontSize(2.5rem, 4, 4rem);
      margin-bottom: 12px;
    }

    p {
      margin-top: 12px;
      margin-bottom: 24px;
    }

    a {
      font-weight: 700;
      @include fontSize(1.4rem, 1.5, 1.7rem);
      margin-bottom: 2rem;
      text-decoration: none;

      @include for-tablet-landscape-up {
        margin-bottom: 4.2rem;
      }

      img {
        max-width: 1.6rem;
        transform: rotate(180deg);
        margin-right: 1.6rem;
        right: 0rem;
        position: relative;
        transition: right $basic-transition-300;
      }

      &:hover {
        img {
          right: 0.8rem;
        }
      }
    }

    .bg {
      z-index: -1;
      position: absolute;
      left: -34rem;
      bottom: -31rem;
      right: -12rem;

      pointer-events: none;

      * {
        pointer-events: none;
      }

      display: none;

      @include for-tablet-portrait-up {
        display: block;
        left: -43rem;
        bottom: -40rem;
        right: -8rem;
      }

      @include for-tablet-landscape-up {
        left: -45rem;
        bottom: -41rem;
        right: -13rem;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      
      &--blue-dark {
        // #002A57
        filter: invert(12%) sepia(33%) saturate(5211%) hue-rotate(195deg)
          brightness(80%) contrast(105%);
      }

      &--blue-light {
        // #0069B4
        filter: invert(26%) sepia(83%) saturate(1824%) hue-rotate(186deg)
          brightness(85%) contrast(101%);
      }

      &--green {
        // #AECD60
        filter: invert(92%) sepia(9%) saturate(2116%) hue-rotate(22deg)
          brightness(75%) contrast(95%);
      }
    }
  }

  &__back {
    background: rgba($color-black, 0.5);
    color: $color-white;
    text-decoration: none;
    padding: 1.2rem 2rem;

    top: 0;
    right: 0;

    transition: all $basic-transition-100;

    &:hover {
      background-color: rgba($color-black, 0.8);
    }
  }

  &__actions {
    display: flex;
    grid-gap: 0.6rem;
    cursor: pointer;

    * {
      width: 3rem;
      height: 3rem;
      display: block;
      background-color: rgba($color-white, 0.3);
      position: relative;

      @include for-tablet-landscape-up {
      }
    }

    &--prev img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(180deg);
    }

    &--next img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      position: absolute;
      transform: rotate(180deg);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__webvr {
    background-color: $color-blue--dark;
    display: none;
    border-radius: 2px;
    cursor: pointer;

    a {
      color: $color-text--inverse;
      text-decoration: none;
      padding: 12px;
      display: block;
      transition: padding $basic-transition-200;
    }

    &:hover a {
      padding: 12px 24px !important;
    }

    @include for-tablet-landscape-up {
      display: block;
    }
  }
}

.side-menu {
  &__button {
    padding: 0 0 12px 12px;

    &-icon {
      position: relative;
      width: 32px;
      height: 24px;

      .line {
        display: block;
        width: 100%;
        height: 3px;
        background-color: $color-text--inverse;
        right: 0;
        position: absolute;

        transition: width $basic-transition-200;

        &:nth-of-type(1) {
          top: 0;
        }

        &:nth-of-type(2) {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:nth-of-type(3) {
          bottom: 0;
        }
      }
    }

    &:hover {
      .line {
        &:first-of-type {
          width: 80%;
        }

        &:last-of-type {
          width: 60%;
        }
      }
    }
  }
}

.actions {
  position: absolute;
  bottom: 24px;
  right: 24px;
  display: grid;
  grid-gap: 16px;
  grid-template: 1fr 1fr / 1fr;

  img,
  a {
    display: block;
    width: 25px;
    opacity: 0.8;
    transition: opacity $basic-transition-200;

    &:hover {
      opacity: 1;
    }
  }

  .gallery {
    transform: scale(0.8);
  }

  @include for-tablet-landscape-up {
    bottom: 24px;
    right: 48px;
    grid-template: 1fr / 1fr 1fr;
  }
}

.credit {
  display: block;
  visibility: visible;
  background-color: transparent;
  top: auto;
  z-index: 3;
  @include fontSize(1.2rem, 1.2, 1.3rem);
  font-weight: 300;
  color: #fff;
  letter-spacing: 0;
  text-decoration: none;
  white-space: nowrap;

  display: none;

  bottom: 8px;
  right: 16px;

  &:hover {
    text-decoration: underline;
  }

  @include for-tablet-landscape-up {
    display: block;
  }
}
